// defines route path and pathname declaration
export const routePaths = {
  home: {
    path: "/home",
    pathName: "home"
  },
  manage: {
    externalConnection: {
      path: "external-connection-manager",
      pathName: "external-connection-manager"
    },
    requestConnection: {
      path: "request-external-connection",
      pathName: "request-external-connection"
    }
  },
  provider: {
    configure: {
      path: "",
      pathName: "configure"
    },
    success: {
      path: "success",
      pathName: "create-success"
    },
    uploadHistory: {
      path: "upload-history",
      pathName: "upload-history"
    },
    digitalTwin: {
      path: "digital-twin-provider",
      pathName: "digital-twin-provider"
    },
    assets: {
      path: "assets",
      pathName: "assets-overview"
    }
  },
  consume: {
    offers: {
      path: "offers",
      pathName: "offers"
    },
    downloadHistory: {
      path: "download-history",
      pathName: "download-history"
    },
    digitalTwin: {
      path: "digital-twin-consume",
      pathName: "digital-twin-consume"
    }
  },
  prepare: {
    models: {
      path: "models",
      pathName: "models"
    },
    mapping: {
      path: "mapping",
      pathName: "mapping"
    },
    converter: {
      path: "converter",
      pathName: "converter"
    }
  },
  govern: {
    policy: {
      path: "policies",
      pathName: "policies"
    },
    publicationTemplates: {
      path: "publication-templates",
      pathName: "publication-templates"
    },
    consumedAgreements: {
      path: "consumed-agreements",
      pathName: "consumed-agreements"
    },
    providedAgreements: {
      path: "provided-agreements",
      pathName: "provided-agreements"
    }
  },
  applications: {
    pcfExchange: {
      path: "pcf-exchange",
      pathName: "pcf-exchange"
    },
    pcfRequests: {
      path: "pcf-requests",
      pathName: "pcf-requests"
    },
    pcfExchangeHistory: {
      path: "pcf-exchange-history",
      pathName: "pcf-exchange-history"
    }
  },
  jobs: {
    path: "/jobs",
    pathName: "jobs"
  }
}
