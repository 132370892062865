<template>
  <TLoadingBar v-show="isLoading" class="loading-bar" :is-loading="isLoading || !isPageLoaded" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import { useLoadingStore } from "@/store/loadingStore"
import TLoadingBar from "@/components/global/TLoadingBar/TLoadingBar.vue"

const loadingStore = useLoadingStore()

const isPageLoaded = ref<boolean>(false)

const isLoading = computed((): boolean => {
  return loadingStore.loadingState
})

window.addEventListener("load", () => {
  isPageLoaded.value = true
})
</script>

<style lang="scss" scoped>
@use "@/styles/global/utilities/variables";

.loading-bar {
  z-index: 101;
  background-color: variables.$white;

  &.is-generic {
    .loading-overlay-bar {
      background-color: variables.$gray-220;
    }
  }

  &.is-authenticated {
    z-index: 10;
  }
}
</style>
