import { createApp } from "vue"
import "./styles/styles.scss"
import { applyPolyfills, defineCustomElements } from "@telekom/scale-components/loader"
import "@telekom/scale-components/dist/scale-components/scale-components.css"
import { OuterClick } from "./directives/outerClick"
import App from "./App.vue"

import store from "@/store"
import router from "@/router"

import i18n from "@/i18n"
import AuthService from "@/common/services/AuthServices"

applyPolyfills().then(() => {
  defineCustomElements(window)
})

const app = createApp(App)

app.directive("outer-click", OuterClick)

app.use(store)
app.use(router)
app.use(i18n)
AuthService.initKeycloak(() => {
  app.mount("#app")
})

export default app
